import { Items } from '../models/categories.models'
export const DATA_ITEMS: Items[] = [
  {
    "_id": "5edde29e140b5f3a6897234f",
    "ItemName": "Red shoes",
    "ItemDescription": "Casual red shoes",
    "ItemSpecification": "Casual red shoes",
    "ItemCategory": "Men Shoes",
    "MaxOrderCount": 20,
    "ItemCurrency": "PKR",
    "ItemPrice": 25,
    "ItemOldPrice": 25,
    "ItemPicList": ["product-1.jpg"],
    "quantitySelected": 2,
    "CartDateTime": new Date(),
    "createdAt": '23-12-2020',
    "ItemRating": "2",
    "IsOutOfInventory": false
  }

]