import { Injectable, Inject } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Observable, of, merge, Subject, BehaviorSubject } from 'rxjs';
import { scan, startWith, map, tap, combineLatest, switchMap, skipWhile, shareReplay, debounceTime, publish, refCount, share } from 'rxjs/operators';
import { Items } from '../models/categories.models';
import { DATA_ITEMS } from './data';
import { CurrencyService } from './currency.service';

export interface Totals {
  subTot: number;
  tax: number;
  grandTot: number;
  shipping: number;
}
export interface StateTree {
  store: Items[];
  cart: Items[];
  tot: Totals,
  checkout: boolean;
};


@Injectable({
  providedIn: 'root'
})
export class CartServiceService {

  /**
   * Main Observables
   * 
   */
  private stateTree$ = new BehaviorSubject<StateTree>(null);
  private checkoutTrigger$ = new BehaviorSubject<boolean>(false);
  private cartAdd$ = new Subject<Items>();
  private cartRemove$ = new Subject<Items>();
  /**
   * Main application cart Observable
   * This could start with items from local storage or even an API call
   * We use scan peak at the items within the cart and add and remove
   */
  public get cart$(): Observable<Items[]> {
    return merge(this.cartAdd$, this.cartRemove$).pipe(
      startWith(JSON.parse(localStorage.getItem('cartItems'))),      
      scan((acc: Items[], item: Items) => {
        if (item) {
          if (item.remove) {
            localStorage.setItem("cartItems",JSON.stringify([...acc.filter(i => i.uuid !== item.uuid)]));
            return [...acc.filter(i => i.uuid !== item.uuid)];
          }
          var result = [...acc, item];
          var unique = [];
          const map = new Map();
          for (const item of result) {
            if (!map.has(item._id)) {
              map.set(item._id, true);    // set any value to Map
              const val = (typeof item == 'object') ? Object.assign({}, item) : item;
              unique.push(val);
            }
          }
          unique.forEach(uItem => {
            var duplicate = result.filter(x => x._id == uItem._id);
            uItem.quantitySelected = 0;
            duplicate.forEach(element => {
              uItem.quantitySelected += element.quantitySelected;
            });
          });
          //console.log("get cart:" + JSON.stringify(unique));
          // debugger;
          localStorage.setItem("cartItems",JSON.stringify(unique.sort((obj1, obj2) => { return obj1.CartDateTime - obj2.CartDateTime; })))
          return unique.sort((obj1, obj2) => { return obj1.CartDateTime - obj2.CartDateTime; });
        }
      })
    );
  }

  /**
   * Calcs all Totals from being piped through the cart Observable
   * When an item gets added or removed it will automatically calc
   */
  public get total$(): Observable<Totals> {
    return this.cart$.pipe(
      map(items => {
        let total = 0;
        for (const i of items) {
          total += i.ItemPrice * i.quantitySelected;
        }
        //console.log("get total in cart:" + total);
        return total;
      }),
      map(cost => ({
        subTot: cost,
        tax: .034 * cost,
        grandTot: cost,
        shipping: cost > 1500? 0 : cost > 1000? 100 : cost> 500 ? 50:150
      })
      )
    );
  }

  /**
   * Main Shopping Cart StateTree
   * Combines all dependencies and maps then to the StateTree Object 
   */
  state$: Observable<StateTree> = this.stateTree$.pipe(
    switchMap(() => this.getItems().pipe(
      combineLatest([this.cart$, this.total$, this.checkoutTrigger$]),
      debounceTime(0),
    )),
    map(([store, cart, tot, checkout]: any) => ({ store, cart, tot, checkout })),
    tap(state => {
      if (state.checkout) {
        //console.log('checkout', state);
      }
    }),
    // make sure we share to the world! or just the entire app
    shareReplay(1)
  );

  constructor(private currencyService: CurrencyService,) { }

  // Mock data service call
  public getItems() {
    //console.log("getting items");
    return of(DATA_ITEMS);
  }

  // facade for next of cartAdd subject
  addCartItem(item: Items) {
    this.cartAdd$.next({ ...item, uuid: uuid() });
    //console.log("added to cart: " + this.cartAdd$)
  }
  // facade for next of cartRemove subject
  removeCartItem(item: Items) {
    this.cartRemove$.next({ ...item, remove: true });
  }
  removeCartItems(item: Items[]) {
    for(var i=0;i<item.length;i++){
      this.cartRemove$.next({ ...item[i], remove: true });
    }
    
  }
  // not sure what else to do here so we don't do much
  // have a great day!
  checkout() {
    this.checkoutTrigger$.next(true);
  }
}
