import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ShopSignupService } from '../services/ShopSignup.service';
import { ShopSignUp } from '../models/ShopSignUp.models';
import { MustMatch } from './validation.mustmatch';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {

  shop: ShopSignUp = new ShopSignUp();
  signupForm: FormGroup; // type validation form
  isFormSubmitted: boolean;
  showSuccessDiv:boolean= false;
  constructor(private shopSignUpService: ShopSignupService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.shop.ShopType = "Personal"
    this.shop.Country = "Pakistan"
    this.signupForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Detail: ['', [Validators.required]],
      Address: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      FirstName: ['',[Validators.required]],
      Email: ['', [Validators.required,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      Phone: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      ConfirmPassword: ['', [Validators.required]],
      City: ['', [Validators.required]],
      TermAndCondition: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('Password', 'ConfirmPassword'),
    });
  }


  get type() {
    return this.signupForm.controls;
  }
  PostShop() {

    if (this.signupForm.valid) {
      this.isFormSubmitted = false;
      var datetimehigh = new Date();
      var sDate = datetimehigh.getFullYear() + '' + datetimehigh.getMonth() + '' +
      datetimehigh.getHours() + '' + datetimehigh.getMinutes() + '' + datetimehigh.getSeconds();


    var requestedData={
      'UserName': this.signupForm.get('FirstName').value + sDate,
      'LastName': this.signupForm.get('LastName').value,
      'FirstName': this.signupForm.get('FirstName').value,
      'Name': this.signupForm.get('Name').value,
      'Detail':this.signupForm.get('Detail').value,
      'Address':this.signupForm.get('Address').value,
      'Phone':this.signupForm.get('Phone').value,
      'Country':'Pakistan',
      'City':this.signupForm.get('City').value,
      'Email':this.signupForm.get('Email').value,
      'Password': this.signupForm.get('Password').value,
      'ShopPercentage':"",
      'EmailVerified':this.signupForm.get('Email').value,
      'IsEmployee':false,
      'ShopType':'Personal',
    }

      
    this.shopSignUpService.PostShopSignUp(requestedData).pipe().subscribe(msg => {
      this.showSuccessDiv = true;
    });
     

    } else {
      // validate all form fields
      this.isFormSubmitted = true;
      this.showSuccessDiv = false;
    }

  }
}
