import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Categories } from '../models/categories.models';
import { Items } from '../models/categories.models';
import { currency } from '../../app/environments/currency';


@Injectable({ providedIn: 'root' })
export class CurrencyService {

  currencyalldata;
    constructor(private http: HttpClient) { }
      getData(){
    let url = `${currency.apiUrl}?access_key=e9aade21cbfcf17d677ea8acc396f91b`;
    return this.http.get(url).toPromise();
  }



   
}