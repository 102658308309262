import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { Categories } from '../models/categories.models';
import { Items } from '../models/categories.models';
import { first } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CartServiceService } from '../services/cart-service.service';
import { CurrencyService } from '../services/currency.service';
import { environment } from '../../app/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public form: FormGroup;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
   

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },
    nav: true
  }
  slideOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
   

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  topRatedItems: Items[] = [];
  specialOffterItems: Items[] = [];
  bestSellersItems: Items[] = [];
  bestSellersFirstItem: Items;
  featuredProducts: Items[] = [];
  popularCategories: Categories[] = [];
  featuredCategories: Categories[] = [];
  imagesFolder: string;
  rating3: number;
  cartState$ = this.shoppingCartService.state$;
  selectedFeaturedClass: string ='All';
  constructor(private homeService: HomeService, private shoppingCartService: CartServiceService, private currencyService: CurrencyService,
    private fb: FormBuilder,private confirmationDialogService: ConfirmationDialogService, private router: Router) { 
this.bestSellersFirstItem = new Items();
    this.rating3 = 0;
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: [4]
    });
  }
  setStyles(imgUrl: string) {
    return {
      'background-image': `url('${imgUrl}')`
    }
  }
  ngOnInit(): void {    
    // let element: HTMLElement = document.getElementsByClassName('departments__button')[0] as HTMLElement;
    // element.click();
    this.imagesFolder = `${environment.apiUrl}/uploads/`;
    this.getTopRatedItems();
    this.getSpecialOffterItems();
    this.getBestSellersItems();
    this.getPopularCategories();
    this.getFeaturedProducts();
    this.getFeaturedCategories();
  }
  private getTopRatedItems() {
    this.homeService.getTopRatedItems().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.topRatedItems = items;
    });
  }
  private getSpecialOffterItems() {
    this.homeService.getSpecialOffterItems().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.specialOffterItems = items;
    });
  }
  private getBestSellersItems() {
    this.homeService.getBestSellersItems().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.bestSellersItems = items;
      if(this.bestSellersItems.length>0){
        this.bestSellersFirstItem = this.bestSellersItems[0];
      }
    });
  }
  private getPopularCategories() {
    this.homeService.getPopularCategories().pipe(first()).subscribe(categories => {
      this.popularCategories = categories;
    });
  }
  private getFeaturedProducts() {
    this.homeService.getFeaturedProducts().pipe(first()).subscribe(items => {
console.log(items);
this.changecurrency(items);

      this.featuredProducts = items;
    });
  }
  getFeaturedProductsByCategory(category: any) {
    this.selectedFeaturedClass = category['Name'];
    this.homeService.getFeaturedProductsByCategory(category['_id']).pipe(first()).subscribe(items => {
this.changecurrency(items);
      this.featuredProducts = items;
    });
  }
  changecurrency(items){
                items.forEach(item=>{

       item.ItemCurrency = this.currencyService.currencyalldata.base;
       item.ItemPrice = item.ItemPrice * this.currencyService.currencyalldata.rates[item.ItemCurrency];
      })

  }
  showAllFeaturedItems(value: any){
    this.selectedFeaturedClass = value;
    this.getFeaturedProducts();
  }
  private getFeaturedCategories() {
    this.homeService.getFeaturedCategories().pipe(first()).subscribe(categories => {
      this.featuredCategories = categories;
      console.log(this.featuredCategories)
    });
  }
  addItemToCart(item: Items) {
    console.log(item);
    item.quantitySelected=1;
    item.CartDateTime=new Date;
    console.log(item)
    this.shoppingCartService.addCartItem(item);
    this.confirmationDialogService.confirm(item.ItemName+ ' item is added to the cart')
			.then((confirmed) =>
				confirmed ?  this.router.navigate(['/'])  : this.router.navigate(['/cart']) 
			)
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    //console.log(dateSent)
 
     return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
   }
  remove(item: Items): void {
    this.shoppingCartService.removeCartItem(item);
  }
}
