import {	Component,	OnInit} from '@angular/core';
import {	ActivatedRoute, Router} from '@angular/router';
import {	FormBuilder,	Validators,	FormGroup} from '@angular/forms';
import {	PlatformLocation} from '@angular/common';
import {	first} from 'rxjs/operators';
import {	Subscription} from 'rxjs';


import {	HomeService} from '../services/home.service';
import {	CartServiceService} from '../services/cart-service.service';
import {	OrderService} from '../../app/services/order.service';
import { CurrencyService } from '../services/currency.service';

import {	environment} from '../../app/environments/environment';

import {	Items,	StateTree} from '../models/categories.models';
import {	OrderStatus} from '../models//OrderStatus.model';

import * as countries from "../jsonfiles/countries.json";
import { Item, LabelsAndValues } from '../models/country.models';

@Component({
	selector: 'app-place-order',
	templateUrl: './place-order.component.html',
	styleUrls: ['./place-order.component.css']
})

export class PlaceOrderComponent implements OnInit {
	private subscription: Subscription = new Subscription(); //<== added this
	state = this.cartServiceService.state$.pipe();
	checkoutProducts: StateTree;
	imagesFolder: string;
	orderStatus: OrderStatus[] = [];
	billingDetailForm: FormGroup; // type validation form
	typesubmit: boolean;
	cartState$ = this.cartServiceService.state$;
	cart$ = this.cartServiceService.cart$;
	// subscription: Subscription;
	items: Items[];
	disabledCheckoutButton: boolean = false;
	currentUrlPath: string;
	httpOrHttps: string;
	 currencyselected;
	paymentType = {
		selectedValue: "0"
	};
	// country list model
	countriesList: Item[] = [];
	countries: LabelsAndValues[] = [];
	// set country data in data variable
	data: any = (countries as any).default;
	constructor(private router: Router, private homeService: HomeService, 
		private cartServiceService: CartServiceService,private currencyService: CurrencyService,
		platformLocation: PlatformLocation, public formBuilder: FormBuilder, 
		private orderService: OrderService, private route: ActivatedRoute) {
		this.currentUrlPath = (platformLocation as any).location.href;
		this.httpOrHttps = (platformLocation as any).location.protocol;
		this.typesubmit = false;
	}

	ngOnInit(): void {
		this.imagesFolder = `${environment.apiUrl}/uploads/`;
		console.log(this.imagesFolder);
		this.countriesList = this.data;
		//loop the country data and get the concerning data
		for (var i = 0; i < this.countriesList.length; i++) {
			this.countries.push({
				name: this.countriesList[i].label,
				id: this.countriesList[i].iso3166Code
			});
		}
		this.billingDetailForm = this.formBuilder.group({
			_id: ['-1'],
			FirstName: ['', [Validators.required]],
			LastName: ['', [Validators.required]],
			CustomerNumber: ['', [Validators.required]],
			CustomerAddress: ['', [Validators.required]],
			CustomerEmailAddress: ['', [Validators.required]],
			paymentType: ['online', Validators.required],
			TermAndCondition: [false, Validators.requiredTrue],

			CustomerCity: ['', [Validators.required]],
			CustomerState: ['', [Validators.required]],
			CustomerCountryCode: ['PK', [Validators.required]],
			CustomerPostalCode: ['', [Validators.required]],

		});

		//this.subscription = this.cartState$.subscribe(u => (this.checkoutProducts = u));
		this.subscription.add(this.cartState$.subscribe(u => (this.checkoutProducts = u)));
		

		this.route.queryParams.subscribe(params => {
			
			const token = params['token'];
			const section = params['section'];
			const data = params['data'];
			if (token) {
				this.loadAllOrderStatus(token,data);
				
			}
			else{
				this.loadAllOrderStatus(null,null);
			}

		});

 this.currencyselected = this.currencyService.currencyalldata.base;
	}
	ngOnDestroy(){ //<== added this
        this.subscription.unsubscribe();
    }
	private loadAllOrderStatus(token: any,data: any) {
		this.orderService.getAllOrderStatusList().pipe(first()).subscribe(orderStatus => {
			this.orderStatus = orderStatus;
			if(token){
				this.verifyToken(token,data);
			}
			
			console.log(this.orderStatus);
		});
	}

	get type() {
		return this.billingDetailForm.controls;
	}

	/**
	 * Type validation form submit data
	 */
	submitBillingDetails() {

		if (this.billingDetailForm.valid) {
			this.typesubmit = false;
			
			var totalPrice;
			var shippingPrice;
			this.subscription = this.cartServiceService.state$.subscribe((state: StateTree) => 
			{
					this.items = state.cart;
					totalPrice = state.tot.grandTot;
					shippingPrice = state.tot.shipping;
					console.log(this.items);
			});
			var orderDetails ={
				totalPrice: totalPrice,
				shippingPrice:shippingPrice,
				items: this.items,
			}
			var filteredOrderStatus = this.orderStatus.filter(x => x.Description.trim().toLocaleLowerCase() === "order received");
			var idOfOrderStatus = '5ee79314ffbdbb6b2c1b789b';
			if (filteredOrderStatus) {
				idOfOrderStatus = filteredOrderStatus[0]["_id"];
			}
			var countryObject = this.countries.filter(x => x.id === this.billingDetailForm.get('CustomerCountryCode').value);
			if (this.billingDetailForm.get('paymentType').value === 'online'){
				var billingObject = {
					'FirstName': this.billingDetailForm.get('FirstName').value,
					'LastName': this.billingDetailForm.get('LastName').value,
					'CustomerAddress': this.billingDetailForm.get('CustomerAddress').value,
					'CustomerEmailAddress': this.billingDetailForm.get('CustomerEmailAddress').value,
					'CustomerNumber': this.billingDetailForm.get('CustomerNumber').value,
					'CustomerCity': this.billingDetailForm.get('CustomerCity').value,
					'CustomerState': this.billingDetailForm.get('CustomerState').value,
					'CustomerPostalCode': this.billingDetailForm.get('CustomerPostalCode').value,
					'CustomerCountryCode': this.billingDetailForm.get('CustomerCountryCode').value,
					'CustomerCountry': countryObject[0].name,
					'IsDeleted': false,
					'CreatedBy': 'Public',
					'UpdatedBy': 'Public'
				};
				this.submitOnlineBillingDetails(billingObject, orderDetails);
			}
			else{
				var object = {
					'FirstName': this.billingDetailForm.get('FirstName').value,
					'LastName': this.billingDetailForm.get('LastName').value,
					'CustomerAddress': this.billingDetailForm.get('CustomerAddress').value,
					'CustomerEmailAddress': this.billingDetailForm.get('CustomerEmailAddress').value,
					'CustomerNumber': this.billingDetailForm.get('CustomerNumber').value,
					'CustomerCity': this.billingDetailForm.get('CustomerCity').value,
					'CustomerState': this.billingDetailForm.get('CustomerState').value,
					'CustomerPostalCode': this.billingDetailForm.get('CustomerPostalCode').value,
					'CustomerCountryCode': this.billingDetailForm.get('CustomerCountryCode').value,
					'CustomerCountry': countryObject[0].name,
					'OrderStatusID': idOfOrderStatus,
					'OrderItem': this.items,
					'TotalPrice': totalPrice,
					'OrderType': 'Cash',
					'OrderRefNumber' : '',
					'Currency': 'PKR',
					'ShippingPrice': shippingPrice,
					'IsDeleted': false,
					'CreatedBy': 'Public',
					'UpdatedBy': 'Public'
				};
				this.submitOrderDetails(object);
			}

		} else {
			// validate all form fields
			this.typesubmit = true;
		}

	}
	submitOrderDetails(orderObject: any){
		
		this.homeService.postOrderDetails(orderObject).subscribe(response => {
			var orderId = '';
			if (response) {
				this.cartServiceService.removeCartItems(this.items);
				orderId = response["Message"];
				this.router.navigate(['/invoice/' + orderId]);
			}
		}, (error) => {
			console.log('error during post is ', error)
		});
	}
	requestOnlinePayment(token: any,clientDetails: any, orderDetails: any){
	
			var currentURL = this.currentUrlPath; //.substring(0, this.currentUrlPath.lastIndexOf("/"));
			var successURL = window.location.protocol + "//" + window.location.host + '/paymentstatus/' + token;
			var cancelURL = window.location.protocol + "//" + window.location.host + '/checkout?token=' + token + '&section=cancel';
			var orderSummary = [];
			var items = orderDetails.items;
			var itemCategories = '';
			var itemsName = '';
			var totalItems = 0;
			for(var i=0; i <items.length;i++ ){
				i === items.length ? itemCategories +=items[i].ItemCategory.Name : itemCategories +=items[i].ItemCategory.Name +',' ;
				i === items.length ? itemsName +=items[i]["ItemName"] : itemsName +=items[i]["ItemName"] +',' ;
				totalItems +=items[i]['quantitySelected'];
			var obj = {
				"ITEM_NAME": items[i]["ItemName"], 
				"QUANTITY": items[i]["quantitySelected"]+'',
				"UNIT_PRICE": items[i]["ItemPrice"]+'',
				"OLD_PRICE": items[i]["ItemPrice"]+'', 
				"CATEGORY": items[i]["ItemCategory"]["Name"],
				"SUB_CATEGORY": items[i]["ItemCategory"]["Name"]
			}
			orderSummary.push(obj);
			}
			const stringData = {
				"CLIENT_NAME": clientDetails.FirstName + ', ' + clientDetails.LastName,
				"RETURN_URL": successURL,
				"CANCEL_URL": cancelURL,
				"ORDER": {
					"SUBTOTAL": orderDetails.totalPrice+orderDetails.shippingPrice+'',
					"OrderSummaryDescription": orderSummary
				},
				"SHIPPING_DETAIL": {
					"SHIPPING_COST": orderDetails.shippingPrice+''
				},
				"ADDITIONAL_DATA": {
					"BILL_TO_FORENAME": clientDetails.FirstName,
					"BILL_TO_SURNAME": clientDetails.LastName,
					"BILL_TO_EMAIL": clientDetails.CustomerEmailAddress,
					"BILL_TO_PHONE": clientDetails.CustomerNumber,
					"BILL_TO_ADDRESS_LINE": clientDetails.CustomerAddress,
					"BILL_TO_ADDRESS_CITY": clientDetails.CustomerCity,
					"BILL_TO_ADDRESS_STATE": clientDetails.CustomerState,
					"BILL_TO_ADDRESS_COUNTRY": clientDetails.CustomerCountryCode,
					"BILL_TO_ADDRESS_POSTAL_CODE": clientDetails.CustomerPostalCode,
					"SHIP_TO_FORENAME": clientDetails.FirstName,
					"SHIP_TO_SURNAME": clientDetails.LastName,
					"SHIP_TO_EMAIL": clientDetails.CustomerEmailAddress,
					"SHIP_TO_PHONE": clientDetails.CustomerNumber,
					"SHIP_TO_ADDRESS_LINE": clientDetails.CustomerAddress,
					"SHIP_TO_ADDRESS_CITY": clientDetails.CustomerCity,
					"SHIP_TO_ADDRESS_STATE": clientDetails.CustomerState,
					"SHIP_TO_ADDRESS_COUNTRY": clientDetails.CustomerCountryCode,
					"SHIP_TO_ADDRESS_POSTAL_CODE": clientDetails.CustomerPostalCode,
				},
				"MerchantFields": {
					"MDD1": "WC",
					"MDD2": "Yes",
					"MDD3": itemCategories,
					"MDD4": itemsName,
					"MDD5": "NO",
					"MDD6": "Standard",
					"MDD7": totalItems+'',
					"MDD8": clientDetails.CustomerCountryCode,
					"MDD20": "NO"
				}
			};
			this.homeService.postPayment(stringData).subscribe(response => {
				if(response["IsSuccess"] && response["ResponseMessage"]=="Success" && response["ResponseCode"]==0){
					var sessionId=btoa(response["Data"]["SESSION_ID"]);
					window.location.href = `${environment.paymentGetWay}`+sessionId;	
				}
			}, (error) => {
				console.log('error during post is ', error)
			});
	}
	submitOnlineBillingDetails(billingObject: any, orderDetails: any){
		this.homeService.submitOnlineBillingDetails(billingObject).subscribe(response => {
			if (response) {
				this.requestOnlinePayment(response["token"],response["customerdata"], orderDetails)
			}
		}, (error) => {
			console.log('error during post is ', error)
		});
	}
	verifyToken(token: any,data:any){
		this.homeService.verifytoken(token,data).subscribe(response => {
			console.log(response)
			if (response) {
				var totalPrice;
				var shippingPrice;
				this.subscription = this.cartServiceService.state$.subscribe((state: StateTree) => 
				{
						this.items = state.cart;
						totalPrice = state.tot.grandTot;
						shippingPrice = state.tot.shipping;
						console.log(this.items);
				});
				var filteredOrderStatus = this.orderStatus.filter(x => x.Description.trim().toLocaleLowerCase() === "order received");
				var idOfOrderStatus = '5ee79314ffbdbb6b2c1b789b';
				if (filteredOrderStatus) {
					idOfOrderStatus = filteredOrderStatus[0]["_id"];
				}
				response.OrderStatusID = idOfOrderStatus;
				response.OrderItem =  this.items;
				response.TotalPrice = totalPrice;
				response.Currency = 'PKR';
				response.ShippingPrice = shippingPrice;
				response.IsDeleted = false;
				response.OrderType = 'Online';
				this.billingDetailForm.patchValue({
					FirstName: response.FirstName,
					LastName: response.LastName,
					CustomerAddress: response.CustomerAddress,
					CustomerEmailAddress: response.CustomerEmailAddress,
					CustomerNumber: response.CustomerNumber,
				});
				this.submitOrderDetails(response);
			}
		}, (error) => {
			console.log('error during post is ', error)
		});
	}
	reset() {
		this.billingDetailForm.reset();
		this.typesubmit = false;
	}
  
}