import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../services/home.service';
import { CurrencyService } from '../services/currency.service';
import { Categories } from '../models/categories.models';
import { Items } from '../models/categories.models';
import { first } from 'rxjs/operators';
import { CartServiceService } from '../services/cart-service.service';
import { environment } from '../../app/environments/environment';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})
export class ShopsComponent implements OnInit {
  selectedCategories : any[]=[];
  selectedCategory:Categories;
  options = [5,10, 20,30, 50];
  optionSelected = 5;
  totalItems: any;
  collection = { count: 0, data: [] };
  config = {
    id: 'custom',
    itemsPerPage: this.optionSelected,
    currentPage: 1,
    totalItems: this.collection.count
  };

  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  public labels: any = {
    previousLabel: '<--',
    nextLabel: '-->',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  filteredItems: Items[] = [];
  latestProducts: Items[] = [];
  categoriesData: Categories[] = [];
  imagesFolder: string;
  categoryId: string;
  constructor(private actRoute: ActivatedRoute, private homeService: HomeService, private currencyService: CurrencyService,
     private shoppingCartService: CartServiceService,private confirmationDialogService: ConfirmationDialogService,
      private router: Router, @Inject(DOCUMENT) private _document: Document) { 
    this.selectedCategory = new Categories();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.categoryId = params.get('id');
      if (this.categoryId === "0") { this.getFilteredItems(); }
      else { this.getItemsByCateogryID(); }
    });
    this.imagesFolder = `${environment.apiUrl}/uploads/`;
    this.getCategoryWithItems();

    this.getLatestProducts();
  }
  private getFilteredItems() {
    this.homeService.getFilteredItems().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.filteredItems = items;
      this.collection.count = this.filteredItems.length;
      this.totalItems = this.filteredItems.length;
    });
  }
  private getItemsByCateogryID() {
    this.homeService.getItemsByCateogryID(this.categoryId).pipe(first()).subscribe(items => {
          this.changecurrency(items);
      this.filteredItems = items;
      this.collection.count = this.filteredItems.length;
      this.totalItems = this.filteredItems.length;
    });
  }
  private getLatestProducts() {
    this.homeService.getLatestProducts().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.latestProducts = items;
    });
  }
    changecurrency(items){
                items.forEach(item=>{

       item.ItemCurrency = this.currencyService.currencyalldata.base;
       item.ItemPrice = item.ItemPrice * this.currencyService.currencyalldata.rates[item.ItemCurrency];

     
      })

  }
  private getCategoryWithItems() {
    this.homeService.getCategoryWithItems().pipe(first()).subscribe(categories => {
     
      this.categoriesData = categories;
     
    });
  }

  onPageChange(event) {
    this.config.currentPage = event;
  }
  onOptionsSelected(event) {
    this.config.itemsPerPage = event;
    this.config.currentPage = 1;
    this.getFilteredItems();
  }
  addItemToCart(item: Items) {
    item.quantitySelected=1;
    item.CartDateTime=new Date;
    console.log(item)
    this.shoppingCartService.addCartItem(item);
    this.confirmationDialogService.confirm(item.ItemName+ ' item is added to the cart')
    .then((confirmed) =>
      confirmed ?  this.router.navigate(['/productsList',this.categoryId])  : this.router.navigate(['/cart']) 
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    //console.log(dateSent)
 
     return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
   }
   updateSelectedCateogriesList(selectedCategory: Categories, values:any){
    this.selectedCategory = selectedCategory;
    const index = this.selectedCategories.indexOf(selectedCategory.categoryID);
    if (index > -1) {
      this.selectedCategories.splice(index, 1);
    }
    else{
      this.selectedCategories.push(selectedCategory.categoryID);
    }
    console.log(this.selectedCategories);
    
     }
     clearSelectedCategoriesList(){
      this.selectedCategories=[];
      for (let item of  this.categoriesData) {
        if (item.isChecked) {
          item.isChecked = false;
        }
      }
      this.collection.count = 0;
      this.totalItems = 0;
      this.config.currentPage = 1;
      this.getItemsByCateogryID();

     
     }
     getFilteredCategoriesData(){
      this.homeService.getItemsByCateogries(this.selectedCategories).pipe(first()).subscribe(items => {
        this.filteredItems = items;
        this.collection.count = this.filteredItems.length;
      this.totalItems = this.filteredItems.length;
      });
     }
}
