import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { Categories } from '../models/categories.models';
import { Items } from '../models/categories.models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {
  currentYear : any;
  topRatedItems: Items[] = [];
  specialOffterItems: Items[] = [];
  popularCategories: Categories[] = [];
  constructor(private homeService: HomeService) {this.currentYear = (new Date()).getFullYear(); }

  ngOnInit(): void {
    this.getTopRatedItems();
    this.getSpecialOffterItems();
    this.getPopularCategories();

  }
  private getTopRatedItems() {
    this.homeService.getTopRatedItems().pipe(first()).subscribe(items => {
      this.topRatedItems = items;
    });
  }
  private getSpecialOffterItems() {
    this.homeService.getSpecialOffterItems().pipe(first()).subscribe(items => {
      this.specialOffterItems = items;
    });
  }

  private getPopularCategories() {
    this.homeService.getPopularCategories().pipe(first()).subscribe(categories => {
      this.popularCategories = categories;
    });
  }

}
