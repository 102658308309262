
export class ShopSignUp {
    UserName: String
    LastName: String
    FirstName: String
    Name: String
    Detail:String
    Address:String
    Phone:String
    Country:String
    City:String
    Email:String
    Password: String
    ShopPercentage:String
    EmailVerified:String
    IsEmployee:boolean
    ShopType:string
}
