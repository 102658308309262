import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../app/environments/environment';
import { OrderStatus } from '../../app/models/orderstatus.model';
import { Order } from '../../app/models/order.model';

@Injectable({ providedIn: 'root' })
export class OrderService {
    constructor(private http: HttpClient) { }

    getAllOrderStatusList() {
        return this.http.get<OrderStatus[]>(`${environment.apiUrl}/Public/OrderStatus`);
    }
    getOrderByID(orderId: any) {
        return this.http.get<Order>(`${environment.apiUrl}/Public/Order/` + orderId);
    }
}