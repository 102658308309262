import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ShopSignUp} from '../models/ShopSignUp.models';
import { ContactUs} from '../models/contactus.models';

@Injectable({
  providedIn: 'root'
})
export class ShopSignupService {

  constructor(private http: HttpClient) { }

    PostShopSignUp(shop:ShopSignUp) {
        return this.http.post(`${environment.apiUrl}/Public/shop`, shop);
    }
    PostContactUsForm(contactus:ContactUs) {
      return this.http.post(`${environment.apiUrl}/Public/contactUs`, contactus);
  }
}
