export class Categories {
    categoryID: string;
    Name: string;
    items: Items[];
    Image: string;
    isChecked: boolean = false;
}

export class Items {
    _id: string;
    ItemName: string;
    ItemDescription: string;
    ItemSpecification: string;
    ItemCategory: string;
    MaxOrderCount: number;
    ItemCurrency: string;
    ItemPrice: number;
    ItemOldPrice: number;
    ItemPicList: string[];
    uuid?: any;
    remove?: boolean;
    quantitySelected: number = 1;
    CartDateTime: Date = new Date();
    createdAt: string;
    ItemRating: string;
    IsOutOfInventory: boolean;
}
export class ItemsImages {
    image: string;
}
export interface Totals {
    subTot: number;
    tax: number;
    grandTot: number;
    shipping: number;
}
export interface StateTree {
    store: Items[];
    cart: Items[];
    tot: Totals,
    checkout: boolean;

};