import { Component, OnInit,ViewChild  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../services/home.service';
import { CurrencyService } from '../services/currency.service';
import { Items } from '../models/categories.models';
import { first } from 'rxjs/operators';
import { CartServiceService } from '../services/cart-service.service';
import { environment } from '../../app/environments/environment';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery-9';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {	ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  rating: number;
  public form: FormGroup;
  relatedItems: Items[]=[];
  itemDetails: Items;
  imagesFolder: string;
  itemId : string;
  ipAddress:any;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },
    nav: true
  }
  constructor(private http: HttpClient,private fb: FormBuilder,
    private actRoute: ActivatedRoute, private homeService: HomeService, 
    private shoppingCartService: CartServiceService,private currencyService: CurrencyService,
    private confirmationDialogService: ConfirmationDialogService, private router: Router) {
this.itemDetails = new Items();
this.rating = 0;
this.form = this.fb.group({
  rating: ['', Validators.required]
});
this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      console.log('th data', data);
      this.ipAddress = data
    })
   }
   selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }
  ngOnInit(): void {
    this.imagesFolder = `${environment.apiUrl}/uploads/`;
    this.actRoute.paramMap.subscribe(params => {
      this.itemId = params.get('id');
      this.getItemDetails(params.get('id'));
      this.galleryOptions = [
        {
          width: '531px',
          height: '620px',
          thumbnailsColumns: 4
        }
      ];
  
    
     });
  
  }

  getItemsRelatedProducts(){
    this.homeService.getItemRelatedProducts(this.itemDetails.ItemCategory['_id']).pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.relatedItems = items;

      console.log(this.relatedItems)
     // this.setGallary(this.itemDetails.ItemPicList);
    });
  }


  //currency
  changecurrency(items){
                items.forEach(item=>{
    
       item.ItemCurrency = this.currencyService.currencyalldata.base;
      item.ItemPrice = item.ItemPrice * this.currencyService.currencyalldata.rates[item.ItemCurrency];
     
      })

  }

  private getItemDetails(productId: any) {
    this.homeService.getItemDetails(productId).pipe(first()).subscribe(items => {
      //currency
      items.ItemCurrency = this.currencyService.currencyalldata.base;
      items.ItemPrice = items.ItemPrice * this.currencyService.currencyalldata.rates[items.ItemCurrency];

      this.itemDetails = items;
      this.getItemsRelatedProducts();
      console.log(this.itemDetails)
      this.setGallary(this.itemDetails.ItemPicList);
    });
  }
  addItemToCart(item: Items) {
    item.quantitySelected=1;
    item.CartDateTime=new Date;
    console.log(item)
    this.shoppingCartService.addCartItem(item); // waleed
    this.confirmationDialogService.confirm(item.ItemName+ ' item is added to the cart')
			.then((confirmed) =>
				confirmed ?  this.router.navigate(['/'])  : this.router.navigate(['/cart']) 
			)
			.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private setGallary(pics:string[])
  {
    var imageGal:NgxGalleryImage[]=[];    
      pics.forEach(element => {
        var img:NgxGalleryImage= new NgxGalleryImage({
          small: this.imagesFolder + element,
          medium: this.imagesFolder + element,
          big: this.imagesFolder + element

        });        
            imageGal.push(img)
      });
      this.galleryImages=imageGal;
  }
  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    //console.log(dateSent)
 
     return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
   }

   changeRatingValue(event: any){
    console.log(this.form.get('rating').value);
    this.homeService.postRatingAgainstItem(this.ipAddress, this.itemId , this.form.get('rating').value).pipe(first()).subscribe(returnData => {
     
      console.log(returnData)
     // this.setGallary(this.itemDetails.ItemPicList);
    });
   }
   
}
