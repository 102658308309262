import {OrderStatus} from './orderstatus.model'

export class OrderItem {
    ItemCurrency: string;
    IsDeleted: boolean;
    _id: string;
    Item?: any;
    ItemPrice: number;
    ItemStatus: string;
    CreatedBy: string;
    UpdatedBy: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
}


export class Order {
    ShippingPrice:number;
    TotalPrice: number;
    OrderNumber: string;
    FirstName: string;
    LastName: string;
    SCurrency: string;
    OrderItem: OrderItem[];
    Currency: string;
    IsDeleted: boolean;
    _id: string;
    CustomerName: string;
    CustomerDescription: string;
    CustomerNumber: string;
    CustomerEmailAddress: string;
    CustomerAddress: string;
    OrderStatus: OrderStatus;
    CreatedBy: string;
    UpdatedBy: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
}


