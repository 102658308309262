// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8',
    authDomain: 'themesbrand-admin.firebaseapp.com',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  // apiUrl: 'http://localhost:5000',
  contentUrl: 'http://localhost:4200',
  paymentGetWay:'https://testpaymentapi.hbl.com/hblpay/site/index.html#/checkout?data=',
  apiUrl: 'http://api.247mymart.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
