import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ShopSignupService } from '../services/ShopSignup.service';
import { ContactUs } from '../models/contactus.models';
import {	NotifierService} from "angular-notifier";
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  private readonly notifier: NotifierService;
  contactus: ContactUs = new ContactUs();
  contactUsForm: FormGroup; // type validation form
  isFormSubmitted: boolean;
  showSuccessDiv:boolean= false;
  constructor(private shopSignUpService: ShopSignupService, public formBuilder: FormBuilder, notifierService: NotifierService) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Email: ['', [Validators.required,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      Subject: ['', [Validators.required]],
      Body: ['', [Validators.required]],
     
    });
  }


  get type() {
    return this.contactUsForm.controls;
  }
  PostContactUsForm() {

    if (this.contactUsForm.valid) {
      this.isFormSubmitted = false;
    


    var requestedData={
      'Name': this.contactUsForm.get('Name').value,
      'Email': this.contactUsForm.get('Email').value,
      'Subject': this.contactUsForm.get('Subject').value,
      'Body': this.contactUsForm.get('Body').value
    }

      
    this.shopSignUpService.PostContactUsForm(requestedData).pipe().subscribe(msg => {
      this.showSuccessDiv = true;
      this.notifier.notify("success", 'You details has been submitted successfully.');
      this.resetForm();
    });
     

    } else {
      // validate all form fields
      this.isFormSubmitted = true;
      this.showSuccessDiv = false;
    }

  }
  resetForm(){
    this.contactUsForm.patchValue({
      Name: "",
      Email: "",
      Subject: "",
      Body: ""
    });
  }

}
