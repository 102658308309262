import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../app/environments/environment';
import { Observable, of, merge, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { OrderService } from '../../app/services/order.service';
import { Order } from '../models/order.model';
import {OrderStatus} from '../models/orderstatus.model'

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  order: Order;
  orderStatus: OrderStatus;
  constructor(private actRoute: ActivatedRoute,
    private orderService: OrderService) { 
      this.order= new Order();
      this.orderStatus = new OrderStatus();
    }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.getOrderByID(params.get('id'));
    });
  }
  private getOrderByID(orderId: any) {
    this.orderService.getOrderByID(orderId).pipe(first()).subscribe(order => {
      this.order = order;
      this.orderStatus = order.OrderStatus;
      console.log(this.order);
    });
  }
  clearLocalStorage(){
    localStorage.setItem("cartItems",JSON.stringify([])); 
  }
  print(){
    let printContents = document.getElementById("invoiceId").innerHTML;
        var a = window.open('', '', 'width=900,height=500');
        a.document.write('<html>');

        a.document.write('<style type="text/css"/> .ql-container.ql-snow { border: none !important; } .ql-editor { padding: 0px !important; } pre {all: unset;white-space: pre-wrap;} </style>');
         a.document.write('<link href="' + environment.contentUrl + '/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" type="text/css" />');
         a.document.write('<link href="' + environment.contentUrl + '/css/MainStyle.css" rel="stylesheet" type="text/css" />');
        a.document.write('<script>function changedisplay(classname, display){ var x = document.getElementsByClassName(classname); var i; for (i = 0; i < x.length; i++) { x[i].style.display = display; }  }</script>');
        a.document.write('<script>function onpageload(){ changedisplay("showforprinting", "block"); changedisplay("hideforprinting", "none"); self.print(); }</script>');
        a.document.write('<body onLoad="onpageload()" style="display: block; padding: 50px">');
        a.document.write(printContents);

        a.document.write('</body></html>');

        a.document.close();
  }
}
