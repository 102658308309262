import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Categories } from '../models/categories.models';
import { Items } from '../models/categories.models';


@Injectable({ providedIn: 'root' })
export class HomeService {
    constructor(private http: HttpClient) { }

    getCategoryWithItems() {
        return this.http.get<Categories[]>(`${environment.apiUrl}/Public/CategoryWithItems`);
    }
    getTopRatedItems() {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/item/rating`);
    }
    getSpecialOffterItems() {
        return this.http.get<Items[]>(`${environment.apiUrl}/public/item/specialOffers/`);
    }
    getBestSellersItems() {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/item/popular/`);
    }
    getPopularCategories() {
        return this.http.get<Categories[]>(`${environment.apiUrl}/Public/PopularCategories`);
    }
     getFeaturedProductsByCategory(catId: any) {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/item/featured/`+catId);
    }
    getFeaturedProducts() {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/item/featured/`);
    }
    getFilteredItems() {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/FilteredItems`);
    }
    getItemsByCateogryID(categoryId: any) {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/ItemsByCategory/`+categoryId);
    }
    getItemsByCateogries(categoriesId: any) {
        return this.http.post<Items[]>(`${environment.apiUrl}/Public/ItemsByCategory/`,categoriesId);
    }
    getLatestProducts() {
        return this.http.get<Items[]>(`${environment.apiUrl}/Public/LatestProducts`);
    }
    getItemDetails(itemId) {
        return this.http.get<Items>(`${environment.apiUrl}/Public/ItemDetails/` + itemId);
    }
    getItemRelatedProducts(categoryId) {
        return this.http.get<Items[]>(`${environment.apiUrl}/public/item/related/` + categoryId);
    }
    postOrderDetails(order: any) {
        return this.http.post(`${environment.apiUrl}/Public/Order/`, order);
    }
    postRatingAgainstItem(ipAddress: any, itemId: any,rating: any) {
        let params = new HttpParams({fromString: 'itemId=' + itemId + '&rating=' + rating});
        return this.http.get<any>(`${environment.apiUrl}/Public/rating/`+itemId+'/'+rating);
    }
    getFeaturedCategories() {
        return this.http.get<Categories[]>(`${environment.apiUrl}/Public/item/featuredCategories/`);
    }

    submitOnlineBillingDetails(billingObject: any) {
        return this.http.post<any>(`${environment.apiUrl}/Public/billingdetails/`, billingObject);
    }
    verifytoken(token: string, data: any) {
        const req = {
            token: token,
            data:data
        }
        return this.http.post<any>(`${environment.apiUrl}/Public/verifytoken/`,req);
    }
    postPayment(order: any) {
        return this.http.post<any>(`${environment.apiUrl}/Public/postPayment/`, order);
    }
   
}