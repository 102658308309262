import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { CurrencyService } from '../services/currency.service';
import { Items } from '../models/categories.models';
import { first } from 'rxjs/operators';
import { CartServiceService } from '../services/cart-service.service';
import { environment } from '../../app/environments/environment';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  state = this.cartServiceService.state$.pipe();
  currentcurr = this.currencyService.currencyalldata.base;
  checkoutProducts: Items[] = [];
  imagesFolder: string;
  constructor(private homeService: HomeService, private cartServiceService: CartServiceService, private currencyService: CurrencyService,) { }

  ngOnInit(): void {
    this.getLatestProducts();
    this.imagesFolder = `${environment.apiUrl}/uploads/`;
  }
  private getLatestProducts() {
    this.homeService.getLatestProducts().pipe(first()).subscribe(items => {
      this.changecurrency(items);
      this.checkoutProducts = items;
    });
  }
    changecurrency(items){
                items.forEach(item=>{

       item.ItemCurrency = this.currencyService.currencyalldata.base;
       item.ItemPrice = item.ItemPrice * this.currencyService.currencyalldata.rates[item.ItemCurrency];

     
      })

  }

  IncreaseItemQantity(item: Items) {        
    if(item.MaxOrderCount < item.quantitySelected + 1)
    {
       alert("Stock ended for this item.");
    }
    else
    {
      var itemNew = item;
      itemNew.quantitySelected += 1;
      this.remove(item);
      this.addItemToCart(itemNew);
    }
  }

  removeItemFromCart(item: Items){
    this.remove(item);
  }

  DecreaseItemQantity(item: Items) {
    if(item.quantitySelected !=1)
    {
      var itemNew = item;    
      itemNew.quantitySelected -= 1;
      this.remove(item);
      if(itemNew.quantitySelected !== 0){
        this.addItemToCart(itemNew);
      }
    }
  }

  addItemToCart(item: Items) {
    console.log(item);
    this.cartServiceService.addCartItem(item);
  }

  remove(item: Items): void {
    this.cartServiceService.removeCartItem(item);
  }

}
